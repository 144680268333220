<template>
  <div
    @click="$emit('click')"
    v-bind:class="{'block': isOpen, 'hidden': !isOpen}"
    class="px-2 pt-2 pb-3 h-full fixed bg-white w-full"
    style="z-index: 100;"
  >
    <MobileNavButton
      v-for="link in arealinks"
      :key="link.title"
      :title="link.title"
      :link="{ name: link.routeName, params: {therapyarea_url: areaUrl} }"
      :isActive="link.routeName == currentRouteName"
      v-on:click.native="buttonAction(link)"
    />
  </div>
</template>

<script>
import MobileNavButton from '@/components/_partials/NavigationBar/MobileNavButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MobileNav',
  components: {
    MobileNavButton
  },
  props: {
    areaUrl: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function() {
    return {}
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
    currentRouteName: function() {
      return this.$route.name
    },
    arealinks() {
      if (this.areaUrl == 'oncology') {
        return [
          {
            routeName: 'Videos',
            title: 'Видео',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'Tests',
            title: 'Тестирование',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'ClinicalCases',
            title: 'Клинический случай',
          },
          {
            routeName: 'ClinicalDatas',
            title: 'Клинические данные',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'OncologyClass',
            title: 'Онкокласс'
          },
          {
            routeName: 'Redcollege',
            title: 'Научная редакция',
            action: () => {
              if (!this.isUserActive) this.$xmodal.open()
            }
          },
          {
            routeName: 'Experts',
            title: 'Эксперты',
            action: () => {
              if (!this.isUserActive) this.$xmodal.open()
            }
          },
          {
            routeName: 'Partners',
            title: 'Партнеры'
          },
          {
            routeName: 'Contacts',
            title: 'Контакты'
          }
        ]
      } else {
        if (this.areaUrl == 'management') {
          return [
            {
              routeName: 'Videos',
              title: 'Видео',
              action: () => {
                this.$store.dispatch('setToIndex')
              }
            },
            {
              routeName: 'Experts',
              title: 'Эксперты',
              action: () => {
                if (!this.isUserActive) this.$xmodal.open()
              }
            },
            {
              routeName: 'Partners',
              title: 'Партнеры'
            },
            {
              routeName: 'Contacts',
              title: 'Контакты'
            }
          ]
        } else {
          if (this.areaUrl === 'pulmonology') {        
            return [
              {
                routeName: 'Videos',
                title: 'Видео',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'Tests',
                title: 'Тестирование',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'ClinicalDatas',
                title: 'Клинические данные',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'PulmonologyClass',
                title: 'Пульмокласс'
              },
              {
                routeName: 'Experts',
                title: 'Эксперты',
                action: () => {
                  if (!this.isUserActive) this.$xmodal.open()
                }
              },
              {
                routeName: 'Partners',
                title: 'Партнеры'
              },
              {
                routeName: 'Contacts',
                title: 'Контакты'
              }
            ]
          } else {
            return [
              {
                routeName: 'Videos',
                title: 'Видео',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'ClinicalDatas',
                title: 'Клинические данные',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'Experts',
                title: 'Эксперты',
                action: () => {
                  if (!this.isUserActive) this.$xmodal.open()
                }
              },
              {
                routeName: 'Partners',
                title: 'Партнеры'
              },
              {
                routeName: 'Contacts',
                title: 'Контакты'
              }  
            ]
          }
        }
      }
    }
  },
  methods: {
    buttonAction(link) {
      if (link.action != undefined) link.action()
    }
  }
}
</script>
