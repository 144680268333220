<template>
  <router-link 
    :to="link" 
    class="block px-3 py-3 text-base font-medium text-primary-500 transition 
           border-b-2 duration-150 ease-in-out"
    :class="{'font-semibold text-secondary': isActive}"
  >
    {{ title }}
  </router-link>
</template>

<script>
export default {
  name: 'MobileNavButton',
  props: {
    title: String,
    link: Object,
    isActive: Boolean
  }
}
</script>
