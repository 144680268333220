<template>
  <nav class="bg-white">
    <div class="mx-auto relative flex items-center justify-between">
      
      <div id="white-half" class="w-full flex flex-wrap md:block md:w-3/5 ">
        <!-- Бургер для мобильного меню -->
        <!--<div 
          v-if="therapyArea"-->
        <div
          class="absolute inset-y-0 left-0 flex items-center md:hidden sm:pl-4">
          <button
            v-on:click="isOpen = !isOpen"
            class="inline-flex items-center justify-center p-2 rounded-md text-primary-500 
                    transition duration-150 ease-in-out">
            <svg class="h-10 w-10" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path v-if="!isOpen" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              <path v-if="isOpen" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Лого -->
        <router-link to="/" class="self-center m-auto">
          <div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start md:pl-6 lg:pl-8">
            <div class="flex-shrink-0">
              <img class="block sm:hidden w-auto h-20" src="@/assets/img/icon.png" alt="" />
              <img v-if="!therapyArea" class="hidden sm:block w-auto h-20" src="@/assets/img/logo.png" alt="" />
              <img v-if="therapyArea" class="hidden sm:block w-auto h-20" :src="require(`@/assets/img/logo/logo-`+ therapyArea.url_name +`.png`)" alt=""/>
            </div>
          </div>
        </router-link>

        <!-- Вход/регистрация для мобильной версии -->
        <div v-if="!user" class="md:hidden absolute right-0 text-primary-500 flex items-center flex-no-wrap pt-5 pr-4 text-sm">
          <p><a @click="showLoginModal()">
            Вход
          </a>/<br>
          <router-link :to="{ name: 'Registration' }">
            Регистрация
          </router-link></p>
        </div>
        <!-- Личный кабинет для мобильной версии -->
        <router-link
          to="/account"
          v-if="user"
          class="block md:hidden absolute mt-5 mr-4 relative text-primary-500 border-primary-500 rounded-full border-2"
          style="width:40px; height:40px"
          >
          <div style="width:35px; height:35px" class="flex justify-center items-center">
            <font-awesome-icon icon="user"/>
          </div>
        </router-link>
        <!-- Пунктирная линия -->
        <svg width="100%" height="4px" class="bg-primary-500">
          <line 
            x1="0" y1="0" x2="100%" y2="0" 
            stroke-width="100%" 
            stroke-dasharray="15% 25% 15% 85%" 
            stroke="#603891"
            stroke-dashoffset="-10%"
          />
        </svg>
      </div>

      <div id="blue-half" :style='cssBg' class="hidden md:block w-3/5 bg-primary-500 pr-2 sm:pr-6 lg:pr-8">
        <div class="flex items-center">
          <svg class="h-20">
            <polygon points="0,0 103,80 0,80"
                fill="white"/>
          </svg>
          
          <!-- Поиск -->
          <SearchInput />

          <!-- Личный кабинет -->
          <router-link
            to="/account"
            v-if="user"
            class="ml-5 block relative text-white rounded-full border-2">
            <div style="width:35px; height:35px" class="flex justify-center items-center">
              <font-awesome-icon icon="user"/>
            </div>
          </router-link>

          <!-- Личный кабинет - почта -->
          <router-link
            to="/account"
            class="ml-2 text-white"
            v-if="user"
            >{{ user.email }}
          </router-link>

          <!-- Вход/регистрация -->
          <div v-if="!user" class="text-gray-300 pl-4 text-sm">
            <a @click="showLoginModal()" class="underline hover:text-white cursor-pointer">
              Вход
            </a>/<br>
            <router-link :to="{ name: 'Registration' }" class="underline hover:text-white cursor-pointer">
              Регистрация
            </router-link>
          </div>
        </div>

        <!-- Пунктирная линия продолжение -->
        <div style="width:100%; height:4px"></div>

      </div>
    </div>

    <!-- Меню для десктопа -->
    <div class="hidden bg-white md:block md:px-6 lg:px-8 shadow-md">
      <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

        <!-- Меню внутри тер. области -->
        <DesktopNav v-if="therapyAreaURL" :areaUrl="therapyAreaURL" key="ter_menu" />

        <!-- Меню внутри лендинга -->
        <div v-if="!therapyAreaURL" id="landing-menu" class="flex flex-wrap" key="land_menu">          
          <DesktopNavButton
            v-for="section in sections"
            :key="section.title"
            :title="section.title"
            :link="{ path: '/' + section.link }"
            :isActive="false"
            v-scroll-to="section.link"
            :id="section.id"
          />
        </div>
      </fade-transition>
    </div>

    <!-- Меню для мобильной версии внутри тер. области -->
    
    <MobileNav
      v-if="therapyAreaURL"
      @click="isOpen = !isOpen"
      :areaUrl="therapyAreaURL"
      :isOpen="isOpen"
    />    

    <!-- Меню для мобильной версии внутри лендинга -->
    <div
      id="mobile-landing-menu"
      v-on:click="isOpen = !isOpen"
      v-if="!therapyArea"
      v-bind:class="{'block': isOpen, 'hidden': !isOpen}"
      class="px-2 pt-2 pb-3 h-full fixed bg-white w-full"
      style="z-index: 100;"
    >
      <MobileNavButton
        v-for="section in sections"
        :key="section.title"
        :title="section.title"
        :link="{ path: '/' + section.link }"
        :isActive="false"
        v-scroll-to="section.link"
        :id="'mobile_' + section.id"
      />
    </div>

  </nav>
</template>

<script>
import DesktopNav from '@/components/_partials/NavigationBar/DesktopNav.vue'
import MobileNav from '@/components/_partials/NavigationBar/MobileNav.vue'
import DesktopNavButton from './NavigationBar/DesktopNavButton.vue'
import MobileNavButton from './NavigationBar/MobileNavButton.vue'
import SearchInput from '@/components/_partials/SearchInput.vue'

import { FadeTransition } from 'vue2-transitions'
import { SlideXLeftTransition } from 'vue2-transitions'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'NavigationBar',
  components: {
    DesktopNav,
    MobileNav,
    DesktopNavButton,
    MobileNavButton,
    FadeTransition,
    SearchInput,
    SlideXLeftTransition
  },
  data: function() {
    return {
      isOpen: false,
      sections: [
        {
          title: 'Слово главного редактора',
          link: '',
          id: 'href-word'
        },
        {
          title: 'Научное общество «Медицинская практика»',
          link: '#practice',
          id: 'href-practice'
        },
        {
          title: 'Партнеры',
          link: '#partners',
          id: 'href-partners'
        },
        {
          title: 'Контакты',
          link: '#contacts',
          id: 'href-contacts'
        }
      ],
      cssBg: {
        backgroundImage: `url(${require('./../../assets/img/bg_nav.png')})`,
        backgroundSize: `cover`,
      }
    }
  },
  computed: {
    ...mapState({
      therapyArea: 'therapyArea',
      loginStatus: state => state.auth.loginStatus,
      user: state => state.auth.user
    }),
    ...mapGetters({
      userArea: 'auth/getFirstUserArea'
    }),
    currentRouteName: function() {
      return this.$route.name
    },
    therapyAreaURL() {
      if (this.$route.params.therapyarea_url)
        return this.$route.params.therapyarea_url
      if (this.$route.name == 'Account' || this.$route.name == 'Search') {
        if (this.$store.state.areaURL) 
          return this.$store.state.areaURL
        if (this.user)
          if (this.user.areas.length)
            return this.user.areas[0].url_name
      }

      if (this.$route.matched[0].path === '/pulmonology/class')
        return 'pulmonology'
      return null
    }
  },
  methods: { 
    showLoginModal() {
      this.$xmodal.open()
    },
    resetIfNeeded(link) {
      if (link.action != undefined) link.action()
    }
  }
}
</script>
