<template>
  <div class="relative hidden md:block w-4/5">
    <input
      v-on:focus="showSearch = true"
      v-on:blur="showSearch = false"
      v-model="query"
      @keyup.enter="search()"
      aria-label="Search"
      style="background-color:#ffffff24"
      class="w-full rounded text-white placeholder-gray-200 text-left
        py-2 px-3 text-sm border border-primary-400 focus:border-primary-300"
      placeholder="Поиск"
    >
    <div 
      v-if="showSearch"
      type="submit"
      class="cursor-pointer text-white right-0 top-0 mr-2 mt-2 absolute"  
    >
      <font-awesome-icon icon="search"/>    
    </div>

    <!-- Results list -->
    <div v-if="results">
      
    </div>    
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'SearchInput',
  data: function() {
    return {
      showSearch: false,
      results: [],
      query: ''
    }
  },
  methods: {
    /*
    search: debounce(function() {
      if (this.query.length > 2) {
        this.$store.getters.api.search({
          data: { query: this.query }
        })
      }
    }, 500)
    */
    search() {
      if (this.$route.name != 'Search') this.$router.push({ name: 'Search'})      
      this.$store.dispatch('search/search', this.query)
        .then()
        .catch()
      this.query = ''
    }
  }
}
</script>

<style>
</style>
