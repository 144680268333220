<template>
  <div class="flex flex-wrap">
    <router-link to="/"
      class="mr-4 pr-2 lg:pr-3 py-2 text-md font-medium leading-5 text-primary-500 
          hover:text-secondary transition duration-150 ease-in-out"
    >
      Главная страница
    </router-link>
    <DesktopNavButton
      v-for="link in arealinks"
      :key="link.title"
      :title="link.title"
      :link="{ name: link.routeName, params: {therapyarea_url: areaUrl} }"
      :isActive="link.routeName == currentRouteName"
      v-on:click.native="buttonAction(link)"
    />
  </div>
</template>

<script>
import DesktopNavButton from '@/components/_partials/NavigationBar/DesktopNavButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DesktopNav',
  components: {
    DesktopNavButton
  },
  props: {
    areaUrl: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {}
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
    currentRouteName: function() {
      return this.$route.name
    },
    arealinks() {
      if (this.areaUrl == 'oncology') {
        return [
          {
            routeName: 'Videos',
            title: 'Видео',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'Tests',
            title: 'Тестирование',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'ClinicalCases',
            title: 'Клинический случай',
          },
          {
            routeName: 'ClinicalDatas',
            title: 'Клинические данные',
            action: () => {
              this.$store.dispatch('setToIndex')
            }
          },
          {
            routeName: 'OncologyClass',
            title: 'Онкокласс'
          },
          {
            routeName: 'Redcollege',
            title: 'Научная редакция',
            action: () => {
              if (!this.isUserActive) this.$xmodal.open()
            }
          },
          {
            routeName: 'Experts',
            title: 'Эксперты',
            action: () => {
              if (!this.isUserActive) this.$xmodal.open()
            }
          },
          {
            routeName: 'Partners',
            title: 'Партнеры'
          },
          {
            routeName: 'Contacts',
            title: 'Контакты'
          }
        ]
      } else {
        if (this.areaUrl == 'management') {
          return [
            {
              routeName: 'Videos',
              title: 'Видео',
              action: () => {
                this.$store.dispatch('setToIndex')
              }
            },
            {
              routeName: 'Experts',
              title: 'Эксперты',
              action: () => {
                if (!this.isUserActive) this.$xmodal.open()
              }
            },
            {
              routeName: 'Partners',
              title: 'Партнеры'
            },
            {
              routeName: 'Contacts',
              title: 'Контакты'
            }
          ]
        } else {
          if (this.areaUrl === 'pulmonology') {       
            return [
              {
                routeName: 'Videos',
                title: 'Видео',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'Tests',
                title: 'Тестирование',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'ClinicalDatas',
                title: 'Клинические данные',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'PulmonologyClass',
                title: 'Пульмокласс'
              },
              {
                routeName: 'Experts',
                title: 'Эксперты',
                action: () => {
                  if (!this.isUserActive) this.$xmodal.open()
                }
              },
              {
                routeName: 'Partners',
                title: 'Партнеры'
              },
              {
                routeName: 'Contacts',
                title: 'Контакты'
              }
            ]
          } else {
            return [
              {
                routeName: 'Videos',
                title: 'Видео',
                action: () => {
                  this.$store.dispatch('setToIndex')
                }
              },
              {
                routeName: 'ClinicalDatas',
                title: 'Клинические данные',
                action: () => {
                  this.$store.dispatch('setToIndex')
                } 
              },
              {
                routeName: 'Experts',
                title: 'Эксперты',
                action: () => {
                  if (!this.isUserActive) this.$xmodal.open()
                }
              },
              {
                routeName: 'Partners',
                title: 'Партнеры'
              },
              {
                routeName: 'Contacts',
                title: 'Контакты'
              }  
            ]
          }
        }
      }
    }
  },
  methods: {
    buttonAction(link) {
      if (link.action != undefined) link.action()
    }
  }
}
</script>
