<template>
  <router-link
    :to="link"
    class="mr-4 pr-2 lg:pr-3 py-2 text-sm font-medium leading-5 text-primary-500 
          hover:text-secondary transition duration-150 ease-in-out"
    :class="{'text-secondary font-semibold': isActive}"
  >
    {{ title }}
  </router-link>
</template>

<script>
export default {
  name: 'DesktopNavButton',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
